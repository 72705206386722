import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Content from './Content'
import Logo from './Logo'

const Wrapper = styled.header`
  width: 100%;
  padding: 72px 0;
  display: flex;

  @media (max-width: 680px) {
    padding: 48px 0;
  }

  @media (max-width: 480px) {
    padding: 30px 0;
  }
`

const Left = styled.div`
  flex: auto;
`

const Header = () => (
  <Content>
    <Wrapper>
      <Left>
        <Link to="/" title="Zur Startseite von STUDOI A45.">
          <Logo />
        </Link>
      </Left>
    </Wrapper>
  </Content>
)

export default Header
