import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Social from './Social'
import Content from './Content'

const Wrapper = styled.footer`
  border-top: 3px solid black;
  padding-top: 6rem;
  padding-bottom: 3rem;
  display: flex;
  align-items: flex-end;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    padding-bottom: 1.5rem;
  }
`

const Intro = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  width: 33.333333%;
`

const Main = styled.div`
  margin-bottom: -3px;

  @media (max-width: 1024px) {
    order: 4;
  }
`

const Meta = styled.div`
  margin-left: auto;

  @media (max-width: 1024px) {
    margin: 1rem 0 2rem 0;
  }
`

const List = styled.ul`
  padding: 0;
  list-style: none;
  display: inline-block;
  display: inline-flex;
  margin: -12px;
  flex-wrap: wrap;

  @media (max-width: 530px) {
    margin-left: -17px;
  }
`

const Item = styled.li`
  position: relative;

  &:not(:last-child)::after {
    content: '|';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2px;
  }
`

const Button = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 12px;
  position: relative;

  &::after {
    content: '';
    height: 2px;
    background: #222;
    position: absolute;
    bottom: 14px;
    left: 12px;
    right: 12px;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &:hover::after {
    opacity: 1;
  }
`

const Footer = () => (
  <Content>
    <Wrapper>
      <Intro>
        Digital
        <br />
        Innovation.
      </Intro>
      <Main>
        <strong>A45 Anstalt &nbsp; — &nbsp;</strong>
        <List>
          <Item>
            <Button to="/kontakt" title="Zur Seite Kontakt">
              Kontakt
            </Button>
          </Item>
          <Item>
            <Button to="/impressum" title="Zur Seite Impressum">
              Impressum
            </Button>
          </Item>
          <Item>
            <Button to="/datenschutz" title="Zur Seite Datenschutz">
              Datenschutz
            </Button>
          </Item>
          <Item>
            <Button to="/feedback" title="Zur Seite Feedback">
              Feedback
            </Button>
          </Item>
        </List>
      </Main>
      <Meta>
        <Social />
      </Meta>
    </Wrapper>
  </Content>
)

export default Footer
