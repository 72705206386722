import styled, { css } from 'styled-components'

const Content = styled.section`
  max-width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1680px;

  @media (max-width: 1280px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: 680px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${props =>
    props.wide &&
    css`
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
`

export default Content
