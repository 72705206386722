import React from 'react'

const Logo = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 201.9 201.91"
    height="120"
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#1d1d1b}' }} />
    </defs>
    <path
      className="cls-1"
      d="M49.6 81.15l8.4-4.88a9 9 0 009 5.91c4.63 0 5.79-1.83 5.79-3.47 0-2.63-2.44-3.66-8.84-5.43s-12.57-4.82-12.57-13 7-13 14.34-13a16.55 16.55 0 0115.61 9.64l-8.23 4.82c-1.47-3-3.48-4.94-7.38-4.94-3 0-4.58 1.52-4.58 3.23 0 2 1 3.29 7.69 5.31 6.46 2 13.72 4.21 13.72 13.23 0 8.24-6.59 13.12-15.86 13.12-8.98.01-14.69-4.26-17.09-10.54zM116.31 57.54h-11v33.31h-9.74V57.54h-11v-9.4h31.72zM121.21 77.06V48.14H131v28.07c0 3.23 1.52 6 6.71 6s6.71-2.74 6.71-6V48.14h9.76v28.92c0 9.15-7.08 14.64-16.47 14.64s-16.5-5.49-16.5-14.64zM89.56 126.5c0 12.07-9 21.35-20.74 21.35H51.74v-42.71h17.08c11.77 0 20.74 9.28 20.74 21.36zm-9.4 0c0-7.33-4.63-12-11.34-12H61.5v23.91h7.32c6.71.04 11.34-4.59 11.34-11.91zM105.33 105.14v42.71h-9.76v-42.71zM111.33 126.5a22.21 22.21 0 1122.21 22.2 22 22 0 01-22.21-22.2zm34.65 0c0-7.63-5.49-12.69-12.44-12.69s-12.45 5.06-12.45 12.69 5.49 12.68 12.45 12.68S146 134.12 146 126.5zM76.75 198.14H60.89l-2.14 6.71H48.08l14.52-42.71H75l14.52 42.71H78.88zM73.82 189l-5-15.68-5 15.68zM123.71 198h-4.27v6.84h-9.88V198h-19.4v-9l13.6-26.85h10.5L100.65 189h8.91v-9.16h9.88V189h4.27zM156.52 191.43c0 9.39-7.32 14.27-15.55 14.27-6.77 0-12.63-2.87-15.25-9.15l8.41-4.88c1 2.81 2.81 4.51 6.84 4.51s5.79-2.13 5.79-4.75-1.77-4.76-5.49-4.76h-13.36l1.77-24.53h24.71v9.15h-15.56l-.43 5.86h3.48c7.93 0 14.64 4.85 14.64 14.28z"
      transform="translate(-29.17 -25.54)"
    />
    <path
      className="cls-1"
      d="M231.08 227.45H29.17V25.54h201.91zm-194-7.94h186v-186h-186z"
      transform="translate(-29.17 -25.54)"
    />
  </svg>
)

export default Logo
