import React from 'react'
import styled, { css } from 'styled-components'

import Facebook from './Icons/Facebook'
import Instagram from './Icons/Instagram'

const List = styled.ul`
  margin: -12px;
  padding: 0;
  list-style: none;
  display: flex;
`

const Item = styled.li`
  display: block;
`

const Button = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  padding: 12px;
  line-height: 1em;

  svg {
    height: 24px;
    width: auto;
  }

  ${props =>
    props.type === 'facebook' &&
    css`
      transform: scale(0.9);
    `}

  path {
    fill: black;
  }
`

const Social = () => (
  <List>
    <Item>
      <Button
        href="https://www.facebook.com/a45digital/"
        target="_blank"
        type="facebook"
        title="A45 auf Facebook"
        rel="noreferrer"
      >
        <Facebook />
      </Button>
    </Item>
    <Item>
      <Button href="https://www.instagram.com/a45digital/" target="_blank" title="A45 auf Instagram" rel="noreferrer">
        <Instagram />
      </Button>
    </Item>
  </List>
)

export default Social
